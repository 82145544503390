import React from "react";
import { orderCurrencies } from "@gemini-common/scripts/constants/currencies";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { HubbleMenuAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/HubbleMenuAccordion";
import { ItemTitle } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/components";
import { StyledItemLink } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/styles";
import { handleChangeFiatSubmit } from "@gemini-ui/components/Header/utils";
import { Text } from "@gemini-ui/design-system";
import { getHubbleFiatIcon } from "@gemini-ui/images/icons/nav/fiat";
import { useIntl } from "@gemini-ui/utils/intl";

export const CurrencyAccordion = ({ defaultFiat, supportedFiat }) => {
  const { intl } = useIntl();

  const { showAlert } = useAlert();

  const fiatIcon = getHubbleFiatIcon({ fiatType: defaultFiat });

  return (
    <HubbleMenuAccordion
      leftElement={<ItemTitle icon={fiatIcon} title={intl.formatMessage({ defaultMessage: "Default currency" })} />}
      content={
        <React.Fragment>
          {orderCurrencies(supportedFiat, defaultFiat).map(item => (
            <StyledItemLink
              indent
              label=""
              key={item}
              active={defaultFiat === item}
              onClick={handleChangeFiatSubmit(item, showAlert)}
              leftElement={
                <Text.Body fontSize="12px" lineHeight="16px" bold>
                  {item}
                </Text.Body>
              }
            />
          ))}
        </React.Fragment>
      }
    />
  );
};
