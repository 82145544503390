/** @jsx jsx */
import * as React from "react";
import { css, Global, jsx } from "@emotion/react";
import { IconChevronDownSmall, IconChevronUpSmall } from "@hubble/icons";
import { truncate } from "lodash";
import Media from "react-media";
import { useMedia } from "react-use";
import { optimizelyClient } from "@gemini-ui/analytics";
import AccountDropdown from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/AccountDropdown";
import AdvancedAccountSelector from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/AdvancedAccountSelector";
import { FAKE_SUBBACCOUNT } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import {
  Container,
  DropdownFauxContainer,
  DropdownLabel,
  VerticalDivider,
} from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/styles";
import HeaderDropdownFauxDisplay from "@gemini-ui/components/Header/navigation/TradeNavMenu/TradeNavDropdown/HeaderDropdownFauxDisplay";
import { PopoverId } from "@gemini-ui/components/Header/navigation/utils";
import { BASE_ZINDEX, BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { navigateToSubaccount } from "@gemini-ui/components/Header/utils";
import Popover from "@gemini-ui/components/Popover";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { usePageDataAndCallOnRefresh } from "@gemini-ui/contexts/PageData";
import { Button, Flex, HubbleButton } from "@gemini-ui/design-system";
import { useSubaccountWithBalance } from "@gemini-ui/hooks/useSubaccountWithBalance";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  enableDropdown?: boolean;
  fancyBorder?: boolean;
  handleError?: (err: Error) => void;
  needsActivateAccount: boolean;
  mobileMenuActive?: boolean;
}

const DisplayAccount = ({ name }: { name: string }) => {
  const truncatedName = truncate(name, { length: 20 });
  return <DropdownLabel>{truncatedName}</DropdownLabel>;
};

const AccountSwitcherMenu = ({
  handleError,
  needsActivateAccount,
  enableDropdown = false,
  fancyBorder = false,
  mobileMenuActive = false,
}: Props) => {
  const { intl } = useIntl();
  const isAdvancedAccountSelectorEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ADVANCED_ACCOUNT_SELECTOR
  );
  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);
  const [displayMenu, setDisplayMenu] = React.useState(false);

  const {
    data: { results, currentPage, total },
    error,
    fetchSubaccounts,
    setParams,
    loading,
  } = useSubaccountWithBalance();

  const isPhone = useMedia(BREAKPOINTS.phoneOnly);
  const isDesktop = useMedia(BREAKPOINTS.desktop);

  const refreshSubaccounts = () => {
    if (isAdvancedAccountSelectorEnabled && displayMenu && total <= 7) {
      // Tick total account value provided the list is not scrollable (becuase it loses scroll pos on reload)
      fetchSubaccounts({ loading: false }); // dont show that its loading
    }
  };

  const {
    pageName,
    templateProps: {
      user: { subaccountHashid, subaccounts: templateSubaccounts },
    },
  } = usePageDataAndCallOnRefresh(refreshSubaccounts);

  const subaccounts = React.useMemo(() => {
    return [
      ...(isAdvancedAccountSelectorEnabled ? results : templateSubaccounts),
      ...(needsActivateAccount ? [FAKE_SUBBACCOUNT] : []),
    ];
  }, [results, needsActivateAccount, isAdvancedAccountSelectorEnabled, templateSubaccounts]);

  const handleMenuClick = () => {
    displayMenu === true && fetchSubaccounts();
    setDisplayMenu(p => !p);
  };

  const onSelectAccount = ({ hashid }: Subaccount) => {
    navigateToSubaccount({ hashid, subaccounts, pageName, handleError });
  };

  const showDropdown = enableDropdown && subaccounts.length >= 1;

  const defaultSubaccount = showDropdown
    ? { ...subaccounts[0], shortName: intl.formatMessage({ defaultMessage: "Select account" }) }
    : null;

  const selectedAccount = templateSubaccounts.find(acct => acct.hashid === subaccountHashid) || defaultSubaccount;

  const loadMore = () => {
    if (total > results.length) {
      setParams(prev => ({ ...prev, offset: results.length }));
    }
  };

  return (
    <React.Fragment>
      <Container
        id={PopoverId.ACCOUNT_SWITCHER}
        data-testid={PopoverId.ACCOUNT_SWITCHER}
        mobileMenuActive={mobileMenuActive}
        isHeaderV2Enabled={isHeaderV2Enabled}
      >
        <Global
          styles={css`
            [data-testid="HeaderLeftNav"] > a {
              ${displayMenu && `z-index: ${BASE_ZINDEX}`}
            }
          `}
        />
        {error ? (
          <Button.Secondary
            ml={3}
            size="sm"
            loading={loading}
            cta={intl.formatMessage({ defaultMessage: "Retry" })}
            onClick={() => fetchSubaccounts()}
          />
        ) : (
          selectedAccount && (
            <Media query={BREAKPOINTS.desktop}>
              {matches => (
                <Popover
                  hasBackdrop={!matches}
                  onClose={handleMenuClick}
                  isOpen={displayMenu}
                  placement="left-start"
                  portalRootSelector={`#${PopoverId.ACCOUNT_SWITCHER}`}
                  css={{
                    [`@media ${BREAKPOINTS.phoneOnly}`]: {
                      width: "100%",
                      transform: "none !important",
                    },
                  }}
                  renderComponent={
                    isAdvancedAccountSelectorEnabled ? (
                      <AdvancedAccountSelector
                        subaccounts={subaccounts}
                        selectedAccount={selectedAccount}
                        loading={loading}
                        currentPage={total > results.length ? currentPage : 0} //
                        displayMenu={displayMenu}
                        handleMenuClick={handleMenuClick}
                        onSelectAccount={onSelectAccount}
                        fetchSubaccounts={fetchSubaccounts}
                        onAddAccount={handleMenuClick}
                        setParams={setParams}
                        loadMore={loadMore}
                      />
                    ) : (
                      <AccountDropdown
                        subaccounts={subaccounts}
                        selectedAccount={selectedAccount}
                        onSelectAccount={onSelectAccount}
                      />
                    )
                  }
                >
                  {isHeaderV2Enabled ? (
                    <HubbleButton.Filter
                      cta={truncate(selectedAccount.name, { length: isPhone ? 16 : 20 })}
                      size="sm"
                      ml={!isPhone ? 2 : 1}
                      mr={isPhone && 1}
                      onClick={handleMenuClick}
                      data-state={displayMenu ? "on" : "off"}
                      rightElement={displayMenu ? <IconChevronUpSmall /> : <IconChevronDownSmall />}
                      data-testid="AccountSwitcherButton"
                    />
                  ) : (
                    <DropdownFauxContainer
                      open={displayMenu}
                      tabIndex={0}
                      onClick={handleMenuClick}
                      onKeyPress={handleMenuClick}
                    >
                      <HeaderDropdownFauxDisplay displayMenu={displayMenu}>
                        <DisplayAccount name={selectedAccount.name} />
                      </HeaderDropdownFauxDisplay>
                    </DropdownFauxContainer>
                  )}
                </Popover>
              )}
            </Media>
          )
        )}
      </Container>
      {isHeaderV2Enabled && isDesktop && (
        <Flex alignItems="center" ml={2} mr={2}>
          <VerticalDivider />
        </Flex>
      )}
    </React.Fragment>
  );
};

export default AccountSwitcherMenu;
