import * as React from "react";
import { changeUser, initialize, logCustomEvent, openSession } from "@braze/web-sdk";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga4";
import {
  ADWORDS_EVENTS,
  AnalyticsEventName,
  AnalyticsProperty,
  BingEvents,
  DEFAULT_BING_EVENT_PROPERTIES,
  DEFAULT_TWITTER_CONVERSION_PROPERTIES,
  FacebookEvents,
  RedditEvents,
  TikTokEvents,
  TwitterCreditCardEvents,
  TwitterEvents,
} from "@gemini-ui/analytics/constants/events";
import { identifyMixpanelUser, mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { initializeFBQ } from "@gemini-ui/analytics/pixel";
import { initializeSnap } from "@gemini-ui/analytics/snapchat";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { User } from "@gemini-ui/constants/templateProps/users";
import { EVENTS_ERROR_TRACKING_LABEL_PREFIX } from "@gemini-ui/utils/constants";
import { getCookieSettings } from "@gemini-ui/utils/cookie";

export const initializeAnalytics = (pageName: PageName, user: User) => {
  identifyMixpanelUser(pageName, user);
};

export const isProdUrl = (): boolean => {
  return (
    window.location.hostname === "exchange.gemini.com" || window.location.hostname === "creditcard.exchange.gemini.com"
  );
};

export const isSandboxUrl = (): boolean => {
  return window.location.hostname === "exchange.sandbox.gemini.com";
};

const DEFAULT_TRACK_OPTIONS = {
  optimizely: false,
};

export const track = (
  eventName: AnalyticsEventName,
  properties?: AnalyticsProperty,
  options?: { optimizely?: boolean; braze?: boolean }
) => {
  const trackOptions = { ...DEFAULT_TRACK_OPTIONS, ...options };
  try {
    mixpanelClient.track(eventName, properties);

    if (trackOptions.braze) trackBrazeEvent(eventName, properties);
    if (trackOptions.optimizely) optimizelyClient.track(eventName);
  } catch (e) {
    Sentry.captureException(e);
  }
};

/**
 * trackLinks: for capturing mixpanel link events
 * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack_links
 *
 * @param elementId Id to attach to element
 * @param name Name of mixpanel event
 * @param properties Properties to pass to event
 */
export const trackLinks = (elementId: string, name: string, properties?: {}) => {
  if (getCookieSettings().allowAnalytics) {
    try {
      mixpanelClient.trackLinks(elementId, name, properties);
    } catch (err) {
      console.error(err);
    }
  }
};

type UseTrackLinkProps<T = Record<string, string>> = {
  selector: string;
  eventName: string;
  properties?: T;
};
export const useTrackLinks = ({ selector, eventName, properties }: UseTrackLinkProps) => {
  React.useEffect(() => {
    if (selector && eventName) {
      trackLinks(selector, eventName, properties);
    }
    // properties requires deep comparison to avoid re-subscribing this link but properties should be static between renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, eventName]);
};

export const initializeGoogleAnalytics = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_GOOGLE_ANALYTICS)) {
    try {
      const measurementId = optimizelyClient.getFeatureVariableString(
        OPTIMIZELY_FEATURE_FLAGS.WEB_GOOGLE_ANALYTICS,
        "GA_MEASUREMENT_ID"
      );
      ReactGA.initialize(measurementId);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};

export const initializeTradeDesk = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_TRADE_DESK)) {
    require("@gemini-ui/analytics/tradedesk");
  }
};

export const initializeFacebookPixel = (email, pixelId = "330632917740535") => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_PIXEL)) {
    // fbq available synchronously when required
    initializeFBQ(email, pixelId);
  }
};

export const trackFacebookPixelEvent = (eventName: FacebookEvents, properties?: AnalyticsProperty) => {
  if (window.fbq) {
    // dedup by timestamp -> filter out by seconds
    const eventId = Math.floor(Date.now() / 1000).toString();
    window.fbq("track", eventName, properties, { eventID: eventId });
  } else {
    Sentry.captureMessage(`${EVENTS_ERROR_TRACKING_LABEL_PREFIX} -- ${eventName} -- Facebook event not tracked`);
  }
};

// todo: this needs to integrate with the analytics queue
export const initializeBraze = (user: User, brazeCfg: any) => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BRAZE_SDK)) {
    initialize(brazeCfg.appIdentifierWeb, {
      baseUrl: brazeCfg.sdkEndpoint,
    });
    changeUser(user?.brazeExternalId || "logged_out_user");
    openSession();
  }
};

export const trackBrazeEvent = (eventName: AnalyticsEventName, properties?: AnalyticsProperty) => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BRAZE_SDK)) {
    logCustomEvent(eventName, properties);
  }
};

export const initializeAdwords = () => {
  window.gtag = () => {};
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_ADWORDS)) {
    // gtag available synchronously when required
    require("@gemini-ui/analytics/adwords");
  }
};

export const trackAdwordsEvent = (
  eventName: (typeof ADWORDS_EVENTS)[keyof typeof ADWORDS_EVENTS],
  properties?: AnalyticsProperty
) => {
  if (window.gtag) {
    window.gtag("event", "conversion", Object.assign({ send_to: eventName }, properties));
  }
};

const twitterEventQueue = [];
let twitterLoadAttempts = 0;
let twitterInterval;

export const trackTwitterEvent = (event: TwitterEvents, properties?: AnalyticsProperty) => {
  const propertiesToSend = { ...DEFAULT_TWITTER_CONVERSION_PROPERTIES, ...properties };
  if (window.twttr) {
    window.twttr.conversion.trackPid(event, propertiesToSend);
  } else {
    twitterInterval && clearInterval(twitterInterval);
    twitterEventQueue.push([event, propertiesToSend]);
    twitterInterval = setInterval(() => {
      if (twitterLoadAttempts >= 10) {
        clearInterval(twitterInterval);
        return;
      }
      if (window.twttr) {
        clearInterval(twitterInterval);
        while (twitterEventQueue.length) {
          window.twttr.conversion.trackPid(...twitterEventQueue.shift());
        }
      }
      twitterLoadAttempts++;
    }, 200);
  }
};

export const trackTwitterCreditCardEvent = (event: TwitterCreditCardEvents, properties?: AnalyticsProperty) => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_TWITTER_ANALYTICS_CREDIT_CARD) && window.twq) {
    window.twq("event", event, properties);
  }
};

export const trackSnapCreditCardEvent = (event: string, pixelId: string, email?: string) => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_SNAPCHAT_ANALYTICS_CREDIT_CARD) && window.snaptr) {
    const snapProps = email ? { user_email: email } : undefined;
    window.snaptr("init", pixelId, snapProps);
    window.snaptr("track", event);
  }
};

export const trackBingEvent = (event: BingEvents, properties?: AnalyticsProperty) => {
  if (window.uetq) {
    const props = { ...DEFAULT_BING_EVENT_PROPERTIES, ...properties };
    window.uetq.push("event", event, props);
  } else {
    Sentry.captureMessage(`${EVENTS_ERROR_TRACKING_LABEL_PREFIX} -- ${event} -- Bing event not tracked`);
  }
};

export const trackRedditEvent = (event: RedditEvents) => {
  if (window.rdt) {
    window.rdt("track", event);
  }
};

export const trackTikTokEvent = (event: TikTokEvents) => {
  if (window.ttq) {
    window.ttq.track(event);
  }
};

export const initializeLinkedIn = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_LINKEDIN)) {
    require("@gemini-ui/analytics/linkedin");
  }
};

export const initializeTwitterGlobal = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_TWITTER)) {
    require("@gemini-ui/analytics/twitter");
  } else {
    window.twq = () => {};
  }
};

export const initializeGTM = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_ACTIVATED_GTM)) {
    require("@gemini-ui/analytics/gtm");
  }
};

export const initializeTwitterGlobalCredit = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_TWITTER_ANALYTICS_CREDIT_CARD)) {
    require("@gemini-ui/analytics/twitter-credit");
  } else {
    window.twq = () => {};
  }
};

export const initializeSnapchat = (email, pixelId = "a6f1d525-34a9-431b-889e-1e7495e83eab") => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_SNAPCHAT)) {
    initializeSnap(email, pixelId);
  } else {
    window.snaptr = () => {};
  }
};

export const initializeReddit = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_REDDIT)) {
    require("@gemini-ui/analytics/reddit");
  } else {
    window.rdt = () => {};
  }
};

export const initializeTikTok = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_TIKTOK)) {
    require("@gemini-ui/analytics/tiktok");
  } else {
    window.ttq = false;
  }
};

export const initializeBing = () => {
  if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BING)) {
    require("@gemini-ui/analytics/bing");
  }
};

// Pass through facade so that everything works as it once did
// Keeping this verbose for now so we know what's available
export const initializeMixpanel = mixpanelClient.initializeMixpanel;
export const setSuperProperty = mixpanelClient.setSuperProperty;
export const setSuperPropertyOnce = mixpanelClient.setSuperPropertyOnce;
export const incrementSuperProperty = mixpanelClient.incrementSuperProperty;
export const setPeopleProperty = mixpanelClient.setPeopleProperty;
export const setPeoplePropertyOnce = mixpanelClient.setPeoplePropertyOnce;
export const incrementPeopleProperty = mixpanelClient.incrementPeopleProperty;
export const aliasUser = mixpanelClient.aliasUser;
export const resetAnalytics = () => {
  try {
    mixpanelClient.reset();
  } catch (e) {
    Sentry.captureException(e);
  }
};
export const getMixpanelId = mixpanelClient.getMixpanelId;

export { EVENTS, PEOPLE, SUPER_PROPERTIES } from "./constants/events";
export { initializeDataDogRUM } from "./datadog";
export { initializeOptimizely, optimizelyClient } from "./optimizely";
export { initializeSift } from "./sift";
