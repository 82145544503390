import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { isGrowProviderType } from "@gemini-ui/constants/earn";

export const useStakeWindow = () => {
  const { pathname } = window.location;
  const parts = pathname.split("/").filter(x => Boolean(x)); // sometimes there is an empty '', filter those out
  const propsParams = parts.slice(1); // i.e. /stake/ETH/validators/1234 will return ['ETH', 'validators', '1234']

  return {
    currency: (propsParams?.[0]?.toLocaleUpperCase() as CurrencyShortNameSupportedCryptos) ?? undefined,
    providerType: isGrowProviderType(propsParams?.[1]) ? propsParams[1] : undefined,
    validatorId: propsParams?.[2] ?? undefined,
  };
};
