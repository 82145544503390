import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconNotificationOnFilled, IconNotificationOnOutlined } from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import { AxiosResponse } from "axios";
import { useMedia } from "react-use";
import { optimizelyClient, track } from "@gemini-ui/analytics";
import { NOTIFICATION_CENTER_EVENTS } from "@gemini-ui/analytics/constants/events";
import { NavIcon } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import {
  getComponentCopy,
  getLatestUnreadId,
  INITIAL_STATE,
  LatestUnreadId,
  markAsRead,
  NotificationCenterState,
  NotificationRequest,
  NotificationResponse,
  NOTIFICATIONS_ENDPOINT,
} from "@gemini-ui/components/Header/navigation/NotificationCenter/constants";
import { NotificationCenterButton } from "@gemini-ui/components/Header/navigation/NotificationCenter/NotificationCenterButton";
import { NotificationCenterPanel } from "@gemini-ui/components/Header/navigation/NotificationCenter/NotificationCenterPanel";
import { NotificationCenterPopover } from "@gemini-ui/components/Header/navigation/NotificationCenter/styles";
import { BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { HubbleButton } from "@gemini-ui/design-system";
import axios from "@gemini-ui/services/axios";
import { useIntl } from "@gemini-ui/utils/intl";

interface NotificationCenterProps {
  mobileMenuActive?: boolean;
}

export function NotificationCenter({ mobileMenuActive = false }: NotificationCenterProps) {
  const [notificationCenterState, setNotificationCenterState] = useState<NotificationCenterState>(INITIAL_STATE);

  const { isOpen, isLoading, isResetting, notificationState, panelState, readParams } = notificationCenterState;
  const { intl } = useIntl();

  const copy = getComponentCopy(intl);
  const iconRef = useRef<HTMLButtonElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);
  const tabletSmDown = useMedia(BREAKPOINTS.tabletSmDown);

  /**
   * Closes the notification center panel and resets the state
   */
  const handleOutsideClick = () => setNotificationCenterState(prev => ({ ...prev, isOpen: false, isResetting: true }));

  const toggleNotificationCenterPanel = () => {
    if (isOpen) {
      handleOutsideClick();
    } else {
      markAsRead(readParams); // The panel is open & user is viewing all notifications, so mark all as read
      setNotificationCenterState(prev => ({ ...prev, isOpen: !prev.isOpen }));
      track(NOTIFICATION_CENTER_EVENTS.VIEW_NOTIFICATIONS.name);
    }
  };

  const fetchNotifications = useCallback(async (params: NotificationRequest, reset = false) => {
    try {
      const { continuationToken, limit } = params;
      let route = `${NOTIFICATIONS_ENDPOINT}/list`;

      if (limit) route = `${route}?limit=${limit}`; // Default is 10, but this can be changed if ever the need arises
      if (continuationToken) route = `${route}?continuationToken=${continuationToken}`;

      const response = await axios.get(route, { withCredentials: true });

      if (response?.data) {
        const { data }: AxiosResponse<NotificationResponse> = response;
        const { notifications } = data;
        const readAllId: LatestUnreadId = getLatestUnreadId(notifications);

        setNotificationCenterState(prev => ({
          ...prev,
          isLoading: false,
          isResetting: false,
          notificationState: data,
          panelState: {
            ...prev.panelState,
            listItems: reset ? [...notifications] : [...prev.panelState.listItems, ...notifications],
          },
          readParams: readAllId ? { readAllId } : {},
        }));
      }
    } catch (e) {
      Sentry.captureException(e);

      setNotificationCenterState(prev => ({
        ...prev,
        isLoading: false,
        isResetting: false,
      }));
    }
  }, []);

  useEffect(() => {
    if (isLoading) fetchNotifications({}); // Only fetch on initial load
    if (isResetting) fetchNotifications({}, isResetting); // Reset after panel has been closed
  }, [fetchNotifications, isLoading, isResetting, panelState]);

  return (
    <div id="NotificationCenterMenu">
      <NotificationCenterPopover
        onClose={handleOutsideClick}
        isOpen={isOpen}
        portalRootSelector="#NotificationCenterMenu"
        renderComponent={
          <NotificationCenterPanel
            aria-busy={isLoading ? "true" : "false"}
            fetchNotifications={fetchNotifications}
            notificationState={notificationState}
            panelState={panelState}
            scrollRef={scrollRef}
          />
        }
      >
        {isHeaderV2Enabled ? (
          <HubbleButton.Tertiary
            data-id="NotificationCenter"
            data-testid="notification-center-button"
            aria-label={copy.HEADING}
            size="sm"
            icon={tabletSmDown ? <IconNotificationOnFilled /> : <IconNotificationOnOutlined />}
            onClick={toggleNotificationCenterPanel}
            style={{ visibility: mobileMenuActive ? "hidden" : "visible" }}
          />
        ) : (
          <NavIcon
            active={isOpen}
            aria-label={copy.HEADING}
            data-id="NotificationCenter"
            data-testid="notification-center-nav-icon"
            display="inline"
            onClick={toggleNotificationCenterPanel}
            pl={0.5}
            pr={0.5}
            ref={iconRef}
            size="sm"
          >
            <NotificationCenterButton canShowNotificationBadge={!isOpen} data-testid="notification-center-button" />
          </NavIcon>
        )}
      </NotificationCenterPopover>
    </div>
  );
}
