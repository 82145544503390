import React from "react";
import {
  IconNumber1Filled,
  IconNumber2Filled,
  IconNumber3Filled,
  IconNumber4Filled,
  IconNumber5Filled,
} from "@hubble/icons";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Text } from "@gemini-ui/design-system";
import {
  AgreementButtonContainer,
  SummaryHeadingContainer,
  SummaryIcon,
  SummaryImage,
  SummaryItemRow,
  SummaryRowsContainer,
} from "@gemini-ui/pages/Earn/Deposit/AcceptAgreement/styles";
import { DepositModalView, ToggleDepositModal } from "@gemini-ui/pages/Earn/Deposit/types";
import { getLegalSummaryItems } from "@gemini-ui/pages/Earn/Deposit/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

const SummaryItems: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { intl } = useIntl();
  const {
    templateProps: {
      user: { countryCode },
    },
  } = usePageData();

  const summaryItems = getLegalSummaryItems(countryCode, intl);

  const iconViews = {
    0: <IconNumber1Filled />,
    1: <IconNumber2Filled />,
    2: <IconNumber3Filled />,
    3: <IconNumber4Filled />,
    4: <IconNumber5Filled />,
  };

  return (
    <SummaryRowsContainer>
      {summaryItems.map((item, idx) => (
        <SummaryItemRow key={`summary-item-${idx}`}>
          <SummaryIcon>{iconViews[idx]}</SummaryIcon>
          <div>
            <Text.Body bold mt={0} size="sm">
              {item.headline}
            </Text.Body>
            <Text.Body size="sm">{item.summary}</Text.Body>
          </div>
        </SummaryItemRow>
      ))}
    </SummaryRowsContainer>
  );
};

interface Props {
  // Use `onToggle` when presenting this modal through the default Earn flow.
  onToggle?: ToggleDepositModal;

  // Use `retailFlow` when presenting this modal through the market purchase flow.
  // This convention follows the existing Earn Agreements flow.
  retailFlow?: (accept: boolean) => void;
}

const AgreementSummary: React.FC<React.PropsWithChildren<Props>> = ({ onToggle, retailFlow }) => {
  const { intl } = useIntl();

  return (
    <React.Fragment>
      <SummaryImage />

      <SummaryHeadingContainer>
        <Text.Subhead size="xl" mb={2}>
          {intl.formatMessage({ defaultMessage: "One more step" })}
        </Text.Subhead>
        <Text.Body size="sm" mb={2}>
          {intl.formatMessage({
            defaultMessage:
              "Next, we'll need you to review two legal agreements. Don't worry - we'll only ask you to do this once. Here are the key points of the agreements.",
          })}
        </Text.Body>
      </SummaryHeadingContainer>
      <SummaryItems />
      <AgreementButtonContainer type="stacked">
        <Button.Primary
          data-testid={testIds.deposit.agreementSummary.continueButton}
          onClick={() => {
            onToggle?.(DepositModalView.EARN_AGREEMENT)();
            retailFlow?.(true);
          }}
          size="lg"
          cta={intl.formatMessage({ defaultMessage: "Go to legal terms" })}
        />
      </AgreementButtonContainer>
    </React.Fragment>
  );
};

export default AgreementSummary;
