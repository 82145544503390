import React from "react";
import { useTheme } from "@emotion/react";
import { CopyListItemToClipboard } from "@gemini-ui/components/CopyListItemToClipboard/CopyListItemToClipboard";
import { CbitTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { HubbleCard, HubbleListItem, List, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/transfers/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

export const CbitDepositDetails = ({
  publicDescription,
  walletAddress,
  walletName,
  firmName,
}: CbitTransferInfoAPIPayload) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <React.Fragment>
      <Text.Body data-testid={testIds.deposit.cbtDepositDetailsText} size="md">
        {intl.formatMessage({
          defaultMessage:
            "Issue a transfer from your CBIT account to Gemini. You will first need to add Gemini’s wallet address as a recipient. If you need help, contact TassatPay customer support.",
        })}
      </Text.Body>

      <HubbleCard variant="filled" mt={2} density="md">
        <Text.Heading size="xs" mt={2} mb={2}>
          {intl.formatMessage({
            defaultMessage: "Recipient information",
          })}
        </Text.Heading>
        <List>
          <HubbleListItem
            density="lg"
            left={<Text.Body>{intl.formatMessage({ defaultMessage: "Firm name" })}</Text.Body>}
            right={<Text.Body bold>{firmName}</Text.Body>}
          ></HubbleListItem>
          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Wallet name" })}
            data={walletName}
            textToCopy={walletName}
          ></CopyListItemToClipboard>
          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Wallet address" })}
            data={walletAddress}
            textToCopy={walletAddress}
          ></CopyListItemToClipboard>
        </List>
      </HubbleCard>

      {Boolean(publicDescription) && (
        <HubbleCard data-testid={testIds.deposit.cbtDepositDetailsRefCodeCard} variant="filled" mt={2} density="md">
          <Text.Heading size="xs" mt={2} mb={2}>
            {intl.formatMessage({
              defaultMessage: "Reference code",
            })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Public description" })}
              data={publicDescription}
              textToCopy={publicDescription}
            ></CopyListItemToClipboard>
          </List>
          <Text.Body color={colorScheme.content.secondary} mb={2} size="xs">
            {intl.formatMessage({
              defaultMessage:
                "Include your account reference code in the memo, instruction, or description field. This code is used to match deposits to your Gemini account. Without it, deposits may be delayed or returned.",
            })}
          </Text.Body>
        </HubbleCard>
      )}
    </React.Fragment>
  );
};
