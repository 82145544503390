import React, { useState } from "react";
import { IconClose, IconMenu } from "@hubble/icons";
import { MobileMenuContent } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/MobileMenuContent";
import {
  StyledMenuButton,
  StyledMenuContent,
} from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/styles";
import { OffCanvasMenuV2Props } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/types";
import { HubbleMenu } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

export const OffCanvasMenuV2 = ({
  advancedTradeUIEnabled,
  defaultFiat,
  groupsInfo,
  institutionName,
  isCustodyAccount,
  isInstitutional,
  isPerpsTransferEligible,
  moreThanOneAccount,
  pageName,
  showCardLink,
  showClearingLink,
  setMobileMenuActive,
  supportedFiat,
  userName,
}: OffCanvasMenuV2Props) => {
  const { intl } = useIntl();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuChange = () => {
    setMobileMenuActive(d => !d);
    setIsMenuOpen(d => !d);
  };

  return (
    <HubbleMenu onOpenChange={handleMenuChange} modal={false}>
      <HubbleMenu.Trigger>
        <StyledMenuButton
          data-testid="mobile-menu-button"
          aria-label={intl.formatMessage({ defaultMessage: "mobile-menu-button" })}
          size="sm"
          icon={isMenuOpen ? <IconClose /> : <IconMenu />}
        />
      </HubbleMenu.Trigger>
      <StyledMenuContent align="end" width="100vw" height="100vh">
        <MobileMenuContent
          advancedTradeUIEnabled={advancedTradeUIEnabled}
          defaultFiat={defaultFiat}
          groupsInfo={groupsInfo}
          institutionName={institutionName}
          isCustodyAccount={isCustodyAccount}
          isInstitutional={isInstitutional}
          isPerpsTransferEligible={isPerpsTransferEligible}
          moreThanOneAccount={moreThanOneAccount}
          pageName={pageName}
          showCardLink={showCardLink}
          showClearingLink={showClearingLink}
          supportedFiat={supportedFiat}
          userName={userName}
        />
      </StyledMenuContent>
    </HubbleMenu>
  );
};
