import React, { useState } from "react";
import { IconCheck, IconCopy } from "@hubble/icons";
import Clipboard, { useClipboard } from "@gemini-ui/components/Clipboard";
import { IconWrapper } from "@gemini-ui/components/CopyListItemToClipboard/styles";
import { Flex, HubbleListItem, Spacer, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export type CopyListItemToClipboardProps = {
  label: string;
  data: string | JSX.Element;
  textToCopy: string | string[];
};

export const CopyListItemToClipboard: React.FC<React.PropsWithChildren<CopyListItemToClipboardProps>> = ({
  label,
  data,
  textToCopy,
}) => {
  const { copied, setCopied } = useClipboard();
  const { intl } = useIntl();

  const [showCopyIcon, setShowCopyIcon] = useState(false);

  const copyText = typeof textToCopy === "string" ? textToCopy : textToCopy.filter(i => Boolean(i)).join("\n");
  const formattedData = typeof data === "string" ? <Text.Body bold>{data}</Text.Body> : data;
  const buttonCopy = intl.formatMessage(
    defineMessage({
      defaultMessage: "Copy {label}",
    }),
    {
      label: label.toLocaleLowerCase(),
    }
  );

  return (
    <HubbleListItem
      data-testid={`${label}-clipboard-listitem`}
      density="lg"
      right={
        <Clipboard
          getText={() => copyText}
          onSuccess={() => setCopied(true)}
          render={onCopyClick => (
            <Spacer
              data-testid={`${label}-spacer`}
              as={Flex}
              onMouseOver={() => setShowCopyIcon(true)}
              onMouseLeave={() => setShowCopyIcon(false)}
            >
              {showCopyIcon && (
                <IconWrapper
                  data-testid={`${label}-icon-wrapper`}
                  title={buttonCopy}
                  aria-label={buttonCopy}
                  onClick={onCopyClick}
                  mr={1}
                >
                  {copied ? (
                    <IconCheck data-testid={`${label}-check-icon`} size="sm" />
                  ) : (
                    <IconCopy data-testid={`${label}-copy-icon`} size="sm" />
                  )}
                </IconWrapper>
              )}
              <div>{formattedData}</div>
            </Spacer>
          )}
        />
      }
    >
      <Text.Body>{label}</Text.Body>
    </HubbleListItem>
  );
};
