import React from "react";
import { Flex, Spacing } from "@gemini-ui/design-system";
import { GrowBuyCard } from "@gemini-ui/pages/Earn/GrowBuy";
import { LearnMoreCard } from "@gemini-ui/pages/Earn/GrowSidebar/LearnMoreCard";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";

const GrowSidebar = () => {
  const { isGrowSidebarModuleEnabled } = useGrowFeatureFlags();

  return (
    <Flex flexDirection="column" gap={Spacing.scale[2]}>
      {isGrowSidebarModuleEnabled && <GrowBuyCard />}

      <LearnMoreCard />
    </Flex>
  );
};

export default GrowSidebar;
