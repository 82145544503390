import React from "react";
import { usePageData } from "@gemini-ui/contexts";
import { Modal } from "@gemini-ui/design-system";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import PlaceRedeem from "@gemini-ui/pages/Earn/Redeem/PlaceRedeem";
import ReviewRedeem from "@gemini-ui/pages/Earn/Redeem/ReviewRedeem";
import RedeemSuccess from "@gemini-ui/pages/Earn/Redeem/Success/index";
import { RedeemProps, RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { useSinglePairDetails } from "@gemini-ui/services/trading/getSinglePairDetails";

export const RedeemFlow = (props: RedeemProps) => {
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const {
    currency,
    asset,
    provider,
    tradingPair,
    redeemStatus: { amount, unstakeSourceType, validatorCount, redemptionStatus },
    router: { currentView },
    closeModal,
  } = useGrowBuy();

  const lastTradePrice = useSinglePairDetails(currency + defaultFiat).data?.lastTradePrice;

  if (!asset) return null;

  return (
    <React.Fragment>
      {currentView === RedeemStep.PlaceRedeem && (
        <PlaceRedeem
          amount={amount}
          asset={asset}
          currency={currency}
          provider={provider}
          defaultFiat={defaultFiat}
          lastTradePrice={lastTradePrice}
          tradingPair={tradingPair}
          unstakeSource={unstakeSourceType}
          validatorCount={validatorCount}
        />
      )}
      {currentView === RedeemStep.ReviewRedeem && (
        <ReviewRedeem
          amount={amount}
          currency={currency}
          defaultFiat={defaultFiat}
          lastTradePrice={lastTradePrice}
          provider={provider}
          tradingPair={tradingPair}
          unstakeSource={unstakeSourceType}
          validatorCount={validatorCount}
        />
      )}
      {currentView === RedeemStep.RedeemSuccess && (
        <RedeemSuccess
          amount={amount}
          currency={currency}
          provider={provider}
          redemptionStatus={redemptionStatus}
          onDone={() => {
            closeModal();
          }}
        />
      )}
    </React.Fragment>
  );
};

const Redeem = (props: RedeemProps) => {
  const { onClose } = props;
  const { goBack } = useGrowBuy();

  return (
    <Modal.MultiStep isOpen onBack={goBack} onClose={onClose}>
      <RedeemFlow {...props} />
    </Modal.MultiStep>
  );
};

export default Redeem;
