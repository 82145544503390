const deposit = {
  placeDeposit: {
    cryptoDropdown: "earn-place-deposit-crypto-dropdown",
    sourceDropdown: "earn-place-deposit-source-dropdown",
    sourceDropdownLabelEnabled: "earn-place-deposit-source-dropdown-label-enabled",
    sourceDropdownLabelDisabled: "earn-place-deposit-source-dropdown-label-disabled",
    sourceItemTradingBalance: "earn-place-deposit-source-item-trading-balance",
    sourceItemBankOrCard: "earn-place-deposit-source-item-bank-or-card",
    amountInput: "earn-place-deposit-amount-input",
    amountInputMaxButton: "earn-place-deposit-amount-input-max-button",
    amountInputHalfButton: "earn-place-deposit-amount-input-half-button",
    amountInputPresetOneButton: "earn-place-deposit-amount-input-preset-one-button",
    amountInputPresetTwoButton: "earn-place-deposit-amount-input-preset-two-button",
    amountInputPresetThreeButton: "earn-place-deposit-amount-input-preset-three-button",
    frequencyInput: "earn-place-deposit-frequency-input",
    projectedInput: "earn-place-deposit-projected-input",
    projectedInputDeprecated: "earn-place-deposit-projected-input-deprecated",
    projectedInputNotional: "earn-place-deposit-projected-input-notional",
    projectedTooltip: "earn-place-deposit-projected-tooltip",
    continueButton: "earn-place-deposit-continue-button",
    taxInfoButton: "earn-place-deposit-tax-info-button",
    rateDetailsButton: "earn-place-deposit-rate-details-button",
  },
  acceptAgreement: {
    scrollSection: "earn-accept-agreement-scroll-section",
    declineButton: "earn-accept-agreement-decline-button",
    acceptButton: "earn-accept-agreement-accept-button",
  },
  reviewDeposit: {
    quoteAmount: "earn-review-deposit-quote-amount",
    quoteNotionalAmount: "earn-review-deposit-quote-notional-amount",
    interestRate: "earn-review-deposit-interest-rate",
    interestRateLabel: "earn-review-deposit-interest-rate-label",
    interestRateRange: "earn-review-deposit-interest-rate-range",
    completeButton: "earn-review-deposit-complete-button",
    cancelButton: "earn-review-deposit-cancel-button",
    titlePrimary: "grow-review-deposit-primary-title",
    titleSecondary: "grow-review-deposit-secondary-title",
    summaryTransferToLabel: "grow-review-deposit-transfer-to-label",
    summaryTransferToValue: "grow-review-deposit-transfer-to-value",
    summaryTransferFromLabel: "grow-review-deposit-transfer-from-label",
    summaryTransferFromValue: "grow-review-deposit-transfer-from-value",
    summaryEstimatedYieldLabel: "grow-review-deposit-estimated-yield-label",
    summaryEstimatedYieldValue: "grow-review-deposit-estimated-yield-value",
    summaryValidatorsLabel: "grow-review-deposit-validators-label",
    summaryValidatorsValue: "grow-review-deposit-validators-value",
    summaryTotalLabel: "grow-review-deposit-total-label",
    summaryTotalValueCrypto: "grow-review-deposit-total-value-crypto",
    summaryTotalValueFiat: "grow-review-deposit-total-value-fiat",
    disclaimer: "grow-review-deposit-disclaimer",
  },
  reviewBuyDeposit: {
    quoteAmount: "earn-review-buy-deposit-quote-amount",
    quoteNotionalAmount: "earn-review-buy-deposit-quote-notional-amount",
    legalCheckbox: "earn-review-deposit-legal-checkbox",
    completeButton: "earn-review-deposit-complete-button",
    cancelButton: "earn-review-deposit-cancel-button",
  },
  success: {
    doneButton: "earn-deposit-success-done-button",
    detailsButton: "grow-stake-success-details-button",
    orderQuantity: "grow-stake-success-quantity",
    orderTotal: "grow-stake-success-total",
    frequency: "grow-stake-success-frequency",
  },
  agreementSummary: {
    continueButton: "earn-agreement-summary-continue-button",
  },
  taxInformation: {
    acceptButton: "grow-tax-information-accept-button",
  },
  assetDisclaimer: {
    confirmButton: "grow-asset-disclaimer-confirm-button",
  },
  rateDetails: {
    modal: "rate-details-modal",
    projectedInterest: {
      list: "projected-interest-list",
      projected1yrInterest: "projected-interest-1yr-interest",
      projected1yrInterestNotional: "projected-interest-1yr-interest-notional",
      projected1yReturn: "projected-interest-1yr-return",
      scheduledInvestmentFrequency: "projected-interest-scheduled-investment-frequency",
      scheduledInvestmentValue: "projected-interest-scheduled-investment-value",
    },
    rateDetails: {
      list: "rate-details-list",
      listItem: "rate-details-list-item",
      maxThreshold: "rate-details-max-threshold",
      rangeDescription: "rate-details-range-description",
      yield: "rate-details-yield",
    },
  },
};

const redeem = {
  placeRedeem: {
    cryptoDropdown: "earn-place-redeem-crypto-dropdown",
    cryptoItem: "earn-place-redeem-crypto-item",
    amountInput: "earn-place-redeem-amount-input",
    continueButton: "earn-place-redeem-continue-button",
    gusdTooltip: "earn-place-redeem-gusd-tooltip",
    gusdToUsdToggle: "earn-place-redeem-convert-to-usd-toggle",
    unstakeSourceProviderType: "grow-unstake-source-provider-type-selection",
    unstakeSourceSelection: "grow-unstake-source-selection",
  },
  reviewRedeem: {
    tooltip: "earn-review-redeem-tooltip",
    amount: "earn-review-redeem-amount",
    notionalAmount: "earn-review-redeem-notional-amount",
    legalCheckbox: "earn-review-redeem-legal-checkbox",
    completeButton: "earn-review-redeem-complete-button",
    cancelButton: "earn-review-redeem-cancel-button",
    fromLabel: "earn-review-redeem-from-label",
    convertDestination: "earn-review-redeem-convert-destination",
    estimatedFundsRelease: "staking-review-withdrawal-estimated-funds-release",
  },
  success: {
    doneButton: "earn-redeem-success-done-button",
    portfolioButton: "earn-redeem-success-portfolio-button",
    message: "earn-redeem-success-message",
  },
  partialSuccess: {
    title: "earn-redeem-partial-success-title",
  },
};

const landingPage = {
  hero: {
    whyUse: "earn-hero-why-use-button",
    faq: "earn-hero-faq-button",
  },
  interestCard: {
    tooltip: "earn-interest-card-tooltip",
    viewBalances: "earn-interest-card-view-balances-button",
    startEarning: "earn-interest-card-start-earning-button",
  },
  balanceCard: {
    card: "grow-landing-balances-card",
    totalBalance: "grow-landing-total-balance",
    carousel: "grow-asset-carousel",
    carouselCard: "grow-asset-carousel-card",
  },
  viewToggle: {
    grid: "grow-landing-view-toggle-grid",
    list: "grow-landing-view-toggle-list",
  },
  assetGrid: {
    hasBalanceCard: "grow-asset-balance-card",
    balance: "combined-balance",
    balanceNotional: "combined-balance-notional",
    aprDisplayCard: "grow-asset-apr-card",
    apr: "asset-apr",
    depositButton: "grow-asset-grid-deposit-btn",
    detailButton: "grow-asset-grid-detail-btn",
  },
  table: {
    assetRows: "retail-earn-asset-rows",
  },
  whyEarnModal: {
    doneButton: "earn-why-modal-close-button",
  },
};

const tieredRatesDisplay = {
  tieredRates: {
    modal: "tiered-rates-modal",
    placeDepositBtn: "tiered-rates-place-deposit-btn",
  },
  tieredBalances: {
    modal: "tiered-balance-modal",
    placeDepositBtn: "tiered-balance-place-deposit-btn",
    redeemButton: "tiered-balance-redeem-btn",
    summary: "tiered-balance-row-list",
    totalBalanceCrypto: "tiered-balance-total-crypto-balance",
    tier: {
      row: "tiered-balance-row",
      yield: "tiered-balance-yield",
      range: "tiered-balance-row-range",
      earned: "tiered-balance-row-earned",
    },
  },
};

const yieldProductInfo = {
  compareProducts: {
    modal: "yield-product-compare-products-modal",
    card: "yield-product-compare-products-card",
    productName: "yield-product-compare-products-product-name",
    learnMoreButton: "yield-product-compare-products-learn-more-button",
    closeButton: "yield-product-compare-products-close-button",
  },
  whatIsProduct: {
    modal: "yield-product-what-is-product-modal",
    title: "yield-product-what-is-product-title",
    faqBtn: "yield-product-what-is-product-faq-button",
  },
};

const assetDetail = {
  header: {
    balanceNotional: "header-balance-notional",
    balance: "header-balance",
    aprDisplay: "header-apr-display",
    depositBtn: "header-deposit-button",
    withdrawBtn: "header-redeem-button",
  },
  overview: {
    apr: "balance-overview-apr",
    lifetimeFees: "balance-overview-lifetime-fees-row",
    lifetimeRewards: "balance-overview-lifetime-rewards-row",
  },
  cards: {
    stakingMethod: "staking-balance-by-type-card",
  },
};

const validators = {
  overview: {
    apr: "validator-overview-apr-line-item",
    activeSince: "validator-overview-active-since-line-item",
    rewards: "validator-overview-rewards-line-item",
  },
  list: {
    pendingBadge: "validator-list-pending-badge",
    apr: "validator-table-row-column-apr",
  },
  details: {
    addressCard: "validator-details-address-card",
    copyToClipboard: "validator-details-copy-to-clipboard-button",
    openExternalDetail: "validator-details-open-external-detail-button",
  },
};

const components = {
  yieldAssetHeader: {
    totalBalanceFiat: "yield-asset-header-total-balance-fiat",
    maxApy: "yield-asset-header-max-apy",
  },
  assetFilter: {
    modalOpenBtn: "asset-filter-modal-open-btn",
    menuToggleBtn: "asset-filter-menu",
  },
  mlc: {
    homepageBanner: "mlc-homepage-banner",
  },
};

const sidebar = {
  learnMoreCard: {
    actionBtn: "grow-learn-more-card-action-button",
  },
};

const buyModule = {
  segmentBtn: "buy-module-segment-btn",
  input: {
    inputAmount: "buy-module-input-input-amount",
    conversionAmount: "buy-module-input-conversion-amount",
    switchBtn: "buy-module-input-switch-btn",
    error: "buy-module-input-error",
  },
  form: {
    reviewBtn: "buy-module-form-review-btn",
  },
  fundingSource: {
    buttonCardFilled: "staking-funding-source-selector-card",
  },
  screens: {
    placeDeposit: "buy-module-screens-place-deposit",
    placeWithdraw: "buy-module-screens-place-withdraw",
  },
};

const successModule = {
  messageAmount: "success-message-amount",
  messageAmountNotional: "success-message-amount-notional",
};

const redemptionModal = {
  banner: "pending-redemptions-banner",
  multiple: {
    notionalAmount: "pending-redemptions-notional-amount",
    section: "pending-redemptions-section",
  },
  single: {
    title: "pending-redemption-title",
    amount: "pending-redemption-amount-remaining",
    amountNotional: "pending-redemption-amount-remaining-notional",
    totalRequestAmount: "pending-redemption-total-amount",
    totalRequestAmountNotional: "pending-redemption-total-amount-notional",
    availableAmount: "pending-redemption-available-amount",
    availableAmountNotional: "pending-redemption-available-amount-notional",
    pendingAmount: "pending-redemption-pending-amount",
    pendingAmountNotional: "pending-redemption-pending-amount-notional",
  },
};

const regionComponents = {
  jurisdictionalEntityBanner: "jurisdictional-entity-banner",
  gb: {
    ratesBanner: "uk-rates-display-banner",
  },
};

const transactionModal = {
  amount: "transaction-modal-amount",
  direction: "transaction-modal-direction",
  details: {
    apr: "transaction-modal-details-apr",
    price: "transaction-modal-details-price",
    paymentMethod: "transaction-modal-details-payment-method",
    total: "transaction-modal-details-total",
    totalNotional: "transaction-modal-details-total-notional",
    destination: "transaction-modal-details-destination",
    source: "transaction-modal-details-origin",
  },
  timeline: "transaction-modal-timeline",
  cancelTransactionBtn: "transaction-modal-cancel-transaction-btn",
};

const eligibilityBanner = {
  jurisdiction: "staking-ineligible-jurisdiction-banner",
  stakingPro: "staking-ineligible-pro-banner",
};

const sidebarStakingModule = {
  deposit: {
    review: {
      title: "sidebar-staking-deposit-review-title",
      subtitle: "sidebar-staking-deposit-review-subtitle",
    },
  },
};

const earnDistributions = {
  homepage: {
    banner: "earn-distributions-homepage-banner",
    bannerNavigateButton: "earn-distributions-homepage-banner-navigate-button",
  },
  pendingRedemptions: {
    bannerLearnMorebutton: "earn-distributions-pending-redemptions-banner-learn-more-button",
    bannerNavigateButton: "earn-distributions-pending-redemptions-banner-navigate-button",
  },
};

export const testIds = {
  assetDetail,
  buyModule,
  components,
  deposit,
  landingPage,
  redeem,
  redemptionModal,
  regionComponents,
  sidebar,
  successModule,
  tieredRatesDisplay,
  transactionModal,
  validators,
  yieldProductInfo,
  eligibilityBanner,
  sidebarStakingModule,
  earnDistributions,
};
