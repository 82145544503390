/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { jsx } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import BigNumber from "bignumber.js";
import { addBusinessDays, addDays, format } from "date-fns";
import { useMedia } from "react-use";
import { bigNumberToDecimalString, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { track } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Money } from "@gemini-ui/components/Money";
import { usePageRefresh } from "@gemini-ui/contexts";
import { Button, Card, Colors, List, ListItem, Text } from "@gemini-ui/design-system";
import LinkText from "@gemini-ui/design-system/Text/LinkText";
import { ReviewTransactionHeader } from "@gemini-ui/pages/Earn/components/ReviewTransactionHeader";
import SummaryLineItem from "@gemini-ui/pages/Earn/Deposit/ReviewBuyDeposit/SummaryLineItem";
import { PrimaryCurrencyDisplay } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { calculateValue, GEMINI_STAKING_AGREEMENT_URL } from "@gemini-ui/pages/Earn/Deposit/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { PlaceRedeemInfo, RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { unstakeSourceTypeRedeemSourceMap } from "@gemini-ui/pages/Earn/Redeem/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { GrowTrackingEvent } from "@gemini-ui/pages/Earn/utils";
import { useStakingWithdraw } from "@gemini-ui/services/staking/withdraw";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props extends Omit<PlaceRedeemInfo, "maxWithdrawDelay" | "gusdToggle" | "isGUSDConvert"> {
  defaultFiat: CurrencyShortNameFiat;
  lastTradePrice: string;
}

const ReviewRedeem = ({
  defaultFiat,
  lastTradePrice,
  currency,
  amount,
  provider,
  validatorCount,
  unstakeSource,
}: Props) => {
  const { intl } = useIntl();
  const { updateRedeemStatus } = useGrowBuy();
  const { requestRefresh } = usePageRefresh();
  const { id: providerId, providerType, maxWithdrawDelay, supportsWeekendOperations } = provider;

  const { submitWithdrawal, loading } = useStakingWithdraw();

  const _isMounted = useRef(false);

  const { showAlert } = useAlert();

  const isMobile = useMedia(BREAKPOINTS.mobileDown);
  const isValidatorUnstake = unstakeSource === UnstakeSourceType.validatorBalance;

  // We may need to display additional UI elements if this is a validator unstake request that affects pending validators.
  const maybeUnstakingPendingValidators =
    isValidatorUnstake && (validatorCount ?? 0) > (provider?.validatorDetails?.activeValidatorCount ?? 0);

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const handleSubmit = useCallback(() => {
    try {
      const { name, properties } = GrowTrackingEvent.WITHDRAW[providerType] ?? {};
      if (Boolean(name)) track(name, { [properties.CURRENCY]: currency, [properties.AMOUNT]: amount });
    } catch (e) {
      Sentry.captureException(e);
    }

    const params = {
      currency,
      providerId,
      // If unstake is from `pooledBalance` or `availableRewardsBalance`, `amount` param will be used by BE
      amount: bigNumberToDecimalString(new BigNumber(amount), currency),
      // If unstake is from `validatorBalance`, `validatorCount` param will be used by BE
      validatorCount: validatorCount,
      // Redemptions from private staking providers can be either the validator balance or the rewards balance
      // We need to provide a `redeemSource` in this case. Do NOT provide a `redeemSource` for pooled staking providers.
      redeemSource: unstakeSourceTypeRedeemSourceMap[unstakeSource],
    };

    submitWithdrawal(params)
      .then(response => {
        if (!_isMounted.current) return;

        // Update local state
        requestRefresh();

        // Update redemption context
        updateRedeemStatus({
          redemptionStatus: response,
          view: RedeemStep.RedeemSuccess,
        });
      })
      .catch(err => {
        if (!_isMounted.current) return;
        showAlert({ type: AlertTypes.ERROR, message: getError(err) });
      });
  }, [
    amount,
    currency,
    updateRedeemStatus,
    providerId,
    providerType,
    requestRefresh,
    showAlert,
    unstakeSource,
    validatorCount,
    submitWithdrawal,
  ]);

  const notionalAmount = useMemo(
    () =>
      calculateValue({
        value: amount,
        lastTradePrice,
      }),
    [amount, lastTradePrice]
  );

  const availableFundsDate = supportsWeekendOperations
    ? format(addDays(new Date(), maxWithdrawDelay), DateFormats.MonthAbbrevDayYear)
    : format(addBusinessDays(new Date(), maxWithdrawDelay), DateFormats.MonthAbbrevDayYear);

  return (
    <React.Fragment>
      <ReviewTransactionHeader
        amount={{ currency, value: amount }}
        amountNotional={{ currency: defaultFiat, value: notionalAmount }}
        validatorCount={validatorCount}
        unstakeSource={unstakeSource}
        userPreferredCurrencyDisplay={PrimaryCurrencyDisplay.CRYPTO}
      />

      <Card variant="filled" padding="none" mt={3}>
        <List>
          <SummaryLineItem
            label={intl.formatMessage({ defaultMessage: "From" })}
            textTestId={testIds.redeem.reviewRedeem.fromLabel}
            text={intl.formatMessage(
              defineMessage({
                defaultMessage: "{providerType, select, Earn {Earn} other {Staking}}",
              }),
              {
                providerType,
              }
            )}
          />
          <SummaryLineItem
            label={intl.formatMessage({ defaultMessage: "To" })}
            text={intl.formatMessage({ defaultMessage: "Trading" })}
          />
          <SummaryLineItem
            label={intl.formatMessage({ defaultMessage: "Estimated release date" })}
            text={availableFundsDate}
            textTestId={testIds.redeem.reviewRedeem.estimatedFundsRelease}
          />
          <SummaryLineItem
            label={intl.formatMessage({ defaultMessage: "Total" })}
            text={<Money currency={currency} value={amount} />}
            textTestId={testIds.redeem.reviewRedeem.amount}
            subText={
              !isValidatorUnstake &&
              intl.formatMessage(
                defineMessage({
                  defaultMessage: "approx. <Money></Money>",
                }),
                {
                  Money: () => <Money currency={defaultFiat} value={notionalAmount} hideTrailingSign />,
                }
              )
            }
            subTextTestId={testIds.redeem.reviewRedeem.notionalAmount}
          />

          <ListItem size="dense" padding="sm" alignItems="center">
            <Text.Legal>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    '{maybeUnstakingPendingValidators, select, true {If the validator being unstaked is “Pending”, we wait for activation on the Beacon Chain before initiating the unstaking process. This may result in longer wait times before receiving your ETH.<br></br><br></br>} other {}}We\'ll transfer your funds as quickly as possible, but note that receiving unstaked assets depends on the blockchain network and may take weeks.<br></br><br></br>By {isMobile, select, true {tapping} other {clicking}} "confirm," you are making a request to unstake your crypto and staking rewards under the Gemini Trust Company, LLC Staking Agreement. You understand that these funds will stop accruing staking rewards once you make this request. <Link>Read more</Link>',
                }),
                {
                  maybeUnstakingPendingValidators,
                  br: () => <br />,
                  isMobile,
                  Link: (str: React.ReactNode) => (
                    <LinkText
                      href={GEMINI_STAKING_AGREEMENT_URL}
                      target="_blank"
                      rel="noreferrer"
                      color={Colors.gray[500]}
                    >
                      {str}
                    </LinkText>
                  ),
                }
              )}
            </Text.Legal>
          </ListItem>
        </List>
      </Card>

      <Button.Group type="action">
        <Button.Primary
          data-testid={testIds.redeem.reviewRedeem.completeButton}
          onClick={() => handleSubmit()}
          loading={loading}
          size="md"
          cta={intl.formatMessage({ defaultMessage: "Confirm" })}
        />
      </Button.Group>
    </React.Fragment>
  );
};

export default ReviewRedeem;
