import React from "react";
import { AccountAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/Accordions/AccountAccordion";
import { CurrencyAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/Accordions/CurrencyAccordion";
import { InterfaceAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/Accordions/InterfaceAccordion";
import { TransferAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/Accordions/TransferAccordion";
import Copyright from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/Copyright";
import { GroupSwitcher } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/GroupSwitcher";
import { PageLinks } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/PageLinks";
import { MobileMenuContentProps } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/types";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { Flex, HubbleMenu } from "@gemini-ui/design-system";

export const MobileMenuContent = ({
  advancedTradeUIEnabled,
  defaultFiat,
  groupsInfo,
  institutionName,
  isCustodyAccount,
  isInstitutional,
  isPerpsTransferEligible,
  moreThanOneAccount,
  pageName,
  showCardLink,
  showClearingLink,
  supportedFiat,
  userName,
}: MobileMenuContentProps) => {
  const multiFiat = supportedFiat.length > 1;

  return (
    <Flex flexDirection="column" width="100%" gap="4px">
      <PageLinks
        advancedTradeUIEnabled={advancedTradeUIEnabled}
        isCustodyAccount={isCustodyAccount}
        pageName={pageName}
        showCardLink={showCardLink}
        showClearingLink={showClearingLink}
      />
      <HubbleMenu.Separator />

      <InterfaceAccordion />
      <TransferAccordion
        isPerpsTransferEligible={isPerpsTransferEligible}
        moreThanOneAccount={moreThanOneAccount}
        pageName={pageName}
      />
      {pageName !== PageName.Trade && multiFiat && (
        <CurrencyAccordion defaultFiat={defaultFiat} supportedFiat={supportedFiat} />
      )}
      <AccountAccordion isCustodyAccount={isCustodyAccount} isInstitutional={isInstitutional} />

      <HubbleMenu.Separator />
      <GroupSwitcher groupsInfo={groupsInfo} institutionName={institutionName} userName={userName} />
      <Copyright />
    </Flex>
  );
};
