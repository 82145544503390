import React from "react";
import { StyledItemLink, SubHeader } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/styles";
import {
  AccordionTitleProps,
  PageLinkProps,
} from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/types";
import { VerificationPendingActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";
import { GlobalModalType, useGlobalModal, VerificationPendingProps } from "@gemini-ui/contexts/GlobalModal";
import { Flex, Text } from "@gemini-ui/design-system";
import { LimitTextLength } from "@gemini-ui/design-system/utils";
import { getIsUserInOnboarding, useLockout } from "@gemini-ui/services/user/lockout";

export const StakeLink = ({ icon, title }: PageLinkProps) => {
  const { lockout } = useLockout();
  const { toggleModal } = useGlobalModal();

  const isOnboardingInProgress = getIsUserInOnboarding(lockout);

  return (
    <PageLink
      href={isOnboardingInProgress ? "#" : "/stake"}
      icon={icon}
      title={title}
      onSelect={() => {
        if (isOnboardingInProgress) {
          toggleModal<VerificationPendingProps>(GlobalModalType.VerificationPending, {
            actionType: VerificationPendingActionType.Stake,
          });
        }
      }}
    />
  );
};

export const PageLink = ({ active, href, icon, testId, title, onSelect }: PageLinkProps) => {
  return (
    <StyledItemLink
      label=""
      data-testid={testId}
      active={active}
      href={href}
      leftElement={<ItemTitle icon={icon} title={title} />}
      onSelect={e => onSelect(e)}
    />
  );
};

export const ItemTitle = ({ icon, title }: AccordionTitleProps) => {
  return (
    <Flex alignItems="center" gap="12px">
      {icon}
      <Text.Body size="sm" bold>
        {title}
      </Text.Body>
    </Flex>
  );
};

export const UserTitle = ({ title, subtitle, margin }) => {
  return (
    <Flex ml={margin} flexDirection="column" justifyContent="center" alignItems="start">
      <Text.Body size="sm" bold>
        {title}
      </Text.Body>
      {subtitle && (
        <SubHeader>
          <LimitTextLength text={subtitle} addParentheses={false} finalTextLength={23} hasTooltip />
        </SubHeader>
      )}
    </Flex>
  );
};
