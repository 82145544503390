import PhoneNumber from "awesome-phonenumber";
import _ from "lodash";
import { CountryName } from "@gemini-common/scripts/constants/Countries";
import { PhoneNumber as PhoneNumberType } from "@gemini-ui/constants/phoneNumber";
import { countryNameMap, euCountryCodes } from "@gemini-ui/pages/register/ConfirmPhone/PhoneNumberInput/constants";
import { CountryCodeData } from "@gemini-ui/pages/register/ConfirmPhone/PhoneNumberInput/ListItem";
const NATIONAL_FORMAT = "national";

export const getIntlPrefix = (country: string): number => {
  return PhoneNumber.getCountryCodeForRegionCode(country);
};

export const getCountryCodeFromPrefix = (intlPrefix: number): string => {
  return PhoneNumber.getRegionCodeForCountryCode(intlPrefix);
};

const mapReplaceDigitsInString = (str: string): (string | RegExp)[] =>
  str.split("").map(char => (char.match(/\d/) ? /\d/ : char));

export const getPhoneNumberMask = (intlPrefix: number): { mask: null | string[]; numberPlaceholder: null | string } => {
  // create a 'mask' for the currently selected country code, e.g. "(000) 000-0000" for the US / code 1
  let mask = null;
  let numberPlaceholder = null;
  const guessedRegion = PhoneNumber.getRegionCodeForCountryCode(intlPrefix);
  if (guessedRegion !== "ZZ") {
    const MOBILE_TYPE = "mobile";
    const exampleNumber = PhoneNumber.getExample(guessedRegion, MOBILE_TYPE).getNumber();
    const exampleStr = exampleNumber && PhoneNumber(exampleNumber).getNumber(NATIONAL_FORMAT);
    if (exampleStr) {
      if (["US", "CA", "GB"].includes(guessedRegion)) {
        // replace has better browser support compared to replaceAll
        numberPlaceholder = String(exampleStr).replace(/[0-9]/g, "0");
        mask = mapReplaceDigitsInString(exampleStr);
      } else {
        numberPlaceholder = null;
        mask = mapReplaceDigitsInString(`${exampleStr}${_.repeat("0", 10)}`);
      }
    }
  }
  return { mask, numberPlaceholder };
};

export const getCountryName = (code: string): CountryName => countryNameMap[code];

export const getCountryItem = (code: string): CountryCodeData => ({
  countryCode: code,
  prefix: getIntlPrefix(code),
  countryName: getCountryName(code),
});

export const getAllPhonePrefixList = (): CountryCodeData[] => {
  const codes = PhoneNumber.getSupportedRegionCodes();
  const countryCodes = codes
    .map(code => getCountryItem(code))
    .filter(item => item.countryName)
    .sort((a, b) => {
      const nameA = a.countryName.toUpperCase();
      const nameB = b.countryName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  return countryCodes;
};

export const getPhonePrefixList = (country: string): CountryCodeData[] => {
  if (euCountryCodes.includes(country?.toUpperCase())) {
    const countryCodes = euCountryCodes
      .map(code => getCountryItem(code))
      .filter(item => item.countryName)
      .sort((a, b) => {
        const nameA = a.countryName.toUpperCase();
        const nameB = b.countryName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    // Allow Ireland users to signup with US phone number
    if (country.toUpperCase() === "IE")
      countryCodes.push({
        countryCode: "US",
        prefix: 1,
        countryName: getCountryName("US"),
      });
    return countryCodes;
  }
  return [];
};

export const parsePhoneNumber = ({ number, intlPrefix }: PhoneNumberType): string => {
  const countryCode = getCountryCodeFromPrefix(intlPrefix);
  return PhoneNumber(number, countryCode).getNumber(NATIONAL_FORMAT);
};
