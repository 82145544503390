/** @jsx jsx */
import styled from "@emotion/styled";
import { BASE_ZINDEX, BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { Colors, Flex, Spacing, Text } from "@gemini-ui/design-system";

export const Container = styled("div")<{ isHeaderV2Enabled: boolean; mobileMenuActive: boolean }>`
  display: flex;
  align-items: center;
  padding-right: ${p => (p.isHeaderV2Enabled ? "none" : Spacing.scale[2])};
  flex-direction: row;
  margin-right: ${p => (p.isHeaderV2Enabled ? "none" : "40px")};
  outline: none;

  visibility: ${p => (p.isHeaderV2Enabled && p.mobileMenuActive ? `hidden` : `visible`)};

  @media ${BREAKPOINTS.phoneOnly} {
    min-width: 150px;
  }
`;

export const DropdownFauxContainer = styled("div")<{ open?: boolean }>`
  border: 1px solid;
  border-color: ${p => (p.open ? Colors.white : Colors.gray[600])};
  border-radius: 8px;
  height: ${Spacing.scale[4]};
  display: flex;
  cursor: pointer;

  &:hover {
    border-color: ${Colors.white};
  }

  @media ${BREAKPOINTS.phoneOnly} {
    height: auto;
    border: 0;
  }
`;
const CONTAINER_WIDTH = 226;
const CONTAINER_WIDTH_WITH_LABEL = 256;
export const DropdownContainer = styled("ul")<{ hasBadge: boolean }>`
  position: absolute;
  top: 44px;
  left: 0;
  ${({ hasBadge }) => `width: ${hasBadge ? CONTAINER_WIDTH_WITH_LABEL : CONTAINER_WIDTH}px;`}
  background: ${Colors.black};
  z-index: ${BASE_ZINDEX + 1};
  box-shadow: 2px 2px 4px rgba(33, 40, 48, 0.5);
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
  @media ${BREAKPOINTS.phoneOnly} {
    width: 100%;
    left: 0;
    top: 50px;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const StyledText = styled(Text.Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AccountItemFlex = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
`;

export const Divider = styled.div`
  border-top: 1px solid ${Colors.gray[700]};
  margin: 4px 0;
`;

export const VerticalDivider = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colorScheme.border.secondary};
  height: 24px;
`;

export const AdvancedAccountSelectorContainer = styled.div`
  position: absolute;
  top: 41px;
  left: 0;
  width: 360px;
  background: ${({ theme }) => theme.colorScheme.elevated.background.modal};
  z-index: ${BASE_ZINDEX + 1};
  box-shadow: 2px 2px 4px rgba(33, 40, 48, 0.5);
  border-radius: 8px;
  @media ${BREAKPOINTS.phoneOnly} {
    width: 100%;
    left: 0;
    top: 50px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    max-height: 540px;
  }
`;

export const ListItemWrapper = styled.div`
  button > div:first-of-type {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  li > * > div > :nth-child(3) {
    overflow-x: visible;
  }

  .active-sub {
    background: ${({ theme }) =>
      theme.isDark
        ? theme.colorScheme.card.background.filled
        : theme.colorScheme.action.background.secondary.neutral.enabled};
  }

  .active-sub:hover {
    background: ${({ theme }) =>
      theme.isDark
        ? theme.colorScheme.card.background.outlined.hover
        : theme.colorScheme.action.background.secondary.neutral.focus};
  }
`;

// Temp hover color until Hubble Menu is ready
// ${Colors.white}14 (#ffffff14): 8% opacity
export const StyledListItem = styled("li")`
  position: relative;
  display: block;
  color: ${Colors.white};
  cursor: pointer;
  font-size: 16px;
  font-weight: medium;
  user-select: none;

  &:hover {
    background: ${Colors.white}14;
  }

  @media ${BREAKPOINTS.phoneOnly} {
    padding-left: ${Spacing.scale[2]};
  }
`;

export const StyledDivider = styled("div")`
  width: 90%;
  margin: auto;
  border-bottom: 1px solid ${Colors.gray[700]};
`;

export const ItemContainer = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const ActiveBar = styled("div")`
  position: absolute;
  width: ${Spacing.scale[0.5]};
  height: 40px;
  z-index: ${BASE_ZINDEX + 2};
  background: ${Colors.brandCyan};

  @media ${BREAKPOINTS.phoneOnly} {
    left: -${Spacing.scale[0.5]};
  }
`;

export const TextContainer = styled("div")`
  padding: ${Spacing.scale[2]} 0 ${Spacing.scale[2]} ${Spacing.scale[2]};

  @media ${BREAKPOINTS.phoneOnly} {
    padding: ${Spacing.scale[2]};
  }
`;

export const DropdownLabel = styled("p")`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${Colors.white};
  letter-spacing: 0;
  user-select: none;
  white-space: nowrap;
  max-width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;

  @media ${BREAKPOINTS.desktop} {
    font-weight: 600;
  }
`;

export const MenuItemType = styled(Text.Body)`
  font-size: 13px;
  color: ${({ theme }) => theme.colorScheme.content.secondary};
  white-space: nowrap;

  @media ${BREAKPOINTS.phoneOnly} {
    font-size: 18px;
  }
`;

export const MenuItemName = styled(Text.Body)`
  font-size: 13px;
  color: ${Colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${CONTAINER_WIDTH - 25}px;
  white-space: nowrap;
`;

// overrides a handful of the styles from the Titan Input
export const SearchInputContainer = styled.div`
  label {
    min-height: auto;
  }

  input {
    height: 40px;
    background: transparent;
    margin: 10px;
    color: ${Colors.white};
    width: calc(100% - 20px);
    font-size: 13px;

    &:focus {
      border-color: ${Colors.gray[400]};
    }
  }
`;

export const FilterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: ${Colors.white};
  height: 100px;
  cursor: default;
`;
