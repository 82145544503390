import React, { ReactElement } from "react";
// @multiFiatAware
import { ReactComponent as BRL } from "./brl.svg";
import { ReactComponent as CHF } from "./chf.svg";
import { ReactComponent as EUR } from "./eur.svg";
import { ReactComponent as GBP } from "./gbp.svg";
import { ReactComponent as USD } from "./usd.svg";
import { ReactComponent as INR } from "./inr.svg";
import { ReactComponent as JPY } from "./jpy.svg";

import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { IconChf, IconEur, IconGbp, IconInr, IconKrw, IconSize, IconUsd } from "@hubble/icons";

export default {
  AUD: USD,
  BRL,
  CAD: USD,
  CHF,
  COP: USD,
  default: USD,
  EUR,
  GBP,
  HKD: USD,
  SGD: USD,
  USD,
  INR,
  JPY,
};

interface GetHubbleFiatIconProps {
  fiatType: CurrencyShortNameFiat;
  size?: IconSize;
}

export const getHubbleFiatIcon = ({ fiatType, size = "sm" }: GetHubbleFiatIconProps): ReactElement => {
  switch (fiatType) {
    case "USD":
      return <IconUsd size={size} />;
    case "EUR":
      return <IconEur size={size} />;
    case "GBP":
      return <IconGbp size={size} />;
    case "CAD":
      return <IconUsd size={size} />;
    case "SGD":
      return <IconUsd size={size} />;
    case "COP":
      return <IconUsd size={size} />;
    case "AUD":
      return <IconUsd size={size} />;
    case "JPY":
      return <IconUsd size={size} />;
    case "BRL":
      return <IconUsd size={size} />;
    case "INR":
      return <IconInr size={size} />;
    case "CHF":
      return <IconChf size={size} />;
    case "KRW":
      return <IconKrw size={size} />;
    case "HKD":
      return <IconUsd size={size} />;
    default:
      return <IconUsd size={size} />;
  }
};
