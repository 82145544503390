import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { border, HubbleButton, HubbleMenu, Spacing } from "@gemini-ui/design-system";
import { ReactComponent as GeminiIcon } from "@gemini-ui/images/gemini-logo-cyan.svg";

export const StyledMenuButton = styled(HubbleButton.Secondary)`
  &[data-state="open"] {
    color: ${({ theme }) => theme.colorScheme.content.primary};
    background: ${({ theme }) => theme.colorScheme.background.secondary};
  }
`;

export const StyledMenuContent = styled(HubbleMenu.Content)`
  background-color: ${({ theme }) => theme.colorScheme.background.primary};
  border-radius: 0;
`;

export const StyledItemLink = styled(HubbleMenu.ItemLink)<{ indent?: boolean; active?: boolean }>`
  border-radius: ${border.radius.full};
  padding: ${p =>
    p.indent
      ? `${Spacing.scale[1]} ${Spacing.scale[2]} ${Spacing.scale[1]} ${Spacing.scale[2]}`
      : `10px ${Spacing.scale[2]}`};
  ${p => p.indent && `margin-left: ${Spacing.scale[4]};`}

  ${p =>
    ({ theme }) =>
      p.active && `background-color: ${theme.colorScheme.background.secondary}`}
`;

export const SubHeader = styled("div")`
  font-size: 12px;
  font-weight: 400;
  line-hight: 16px;
  color: ${({ theme }) => theme.colorScheme.content.secondary};
`;

//  -----  Group Switcher Modal  -----

export const fullScreenStyles = css`
  height: 100vh;
  width: 100%;

  .ModalLayout {
    border-radius: 0;
    height: 100vh;
    max-width: initial;

    .ModalHeader,
    .ModalContent {
      padding: 0;
    }

    .ModalContent {
      height: 100vh;
    }
  }
`;

export const Loader = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.scale[5]};
`;

export const Logo = styled(GeminiIcon)`
  width: 92px;
  height: 92px;
  margin-bottom: ${Spacing.scale[5]};

  @media ${BREAKPOINTS.tabletUp} {
    width: 200px;
    height: 200px;
    margin-bottom: ${Spacing.scale[11]};
  }
`;

export const Title = styled("div")`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: ${Spacing.scale[3]};
  text-align: center;

  @media ${BREAKPOINTS.tabletUp} {
    font-size: 36px;
  }
`;

export const Subtitle = styled("div")`
  font-size: ${Spacing.scale[2]};
  line-height: 1.5;
  text-align: center;
`;
