import React from "react";
import { useTheme } from "@emotion/react";
import { Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const Copyright = () => {
  const { intl, buildGeminiPublicLink } = useIntl();
  const { colorScheme } = useTheme();

  const year = new Date().getFullYear();
  return (
    <Text.Body size="xs" align="center" pt={2} pb={2} mb={6} color={colorScheme.content.secondary}>
      {intl.formatMessage(
        defineMessage({
          defaultMessage:
            "Trust is Our Product™<br></br>For trademarks and patents, please see the <a>Legal Notice</a>.<br></br>© Copyright {year} Gemini Trust Company, LLC. NMLS #1518126",
        }),
        {
          br: () => <br />,
          a: (v: React.ReactNode) => (
            <a
              href={buildGeminiPublicLink("legal/legal-notice")}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline", color: colorScheme.content.secondary }}
            >
              {v}
            </a>
          ),
          year,
        }
      )}
    </Text.Body>
  );
};

export default Copyright;
