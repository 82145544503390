import { datadogLogs, LogsEvent } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { isProdUrl } from "@gemini-ui/analytics";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { DataDogCredentials } from "@gemini-ui/constants/initialData/datadog";
import { User } from "@gemini-ui/constants/templateProps/users";

export const dataDogClient = {
  init: (initConfiguration: import("@datadog/browser-rum-core").RumInitConfiguration): void => {
    datadogRum.init(initConfiguration);
  },
  addRumGlobalContext: (key: string, value: any): void => {
    datadogRum.addRumGlobalContext(key, value);
  },
  setRumGlobalContext: (newContext: object): void => {
    datadogRum.setRumGlobalContext(newContext);
  },
  startSessionReplayRecording: (): void => {
    datadogRum.startSessionReplayRecording();
  },
  stopSessionReplayRecording: (): void => {
    datadogRum.stopSessionReplayRecording();
  },
  onReady: (callback: () => void): void => {
    datadogRum.onReady(callback);
  },
  getUser: (): import("@datadog/browser-core").Context => datadogRum.getUser(),
  setUser: (newUser: import("@datadog/browser-rum-core/cjs/rawRumEvent.types").User): void => {
    datadogRum.setUser(newUser);
  },
  clearUser: (): void => {
    datadogRum.clearUser();
  },
  setUserProperty: (key: any, property: any): void => {
    datadogRum.setUserProperty(key, property);
  },
  addError: (error: unknown, context?: object | undefined): void => {
    datadogRum.addError(error, context);
  },
  getInternalContext: (
    startTime?: number | undefined
  ): import("@datadog/browser-rum-core/cjs/domain/contexts/internalContext").InternalContext | undefined =>
    datadogRum.getInternalContext(startTime),
  version: datadogRum.version,
};

export const beforeSend = (event: LogsEvent) => {
  const IGNORE_ERRORS = [
    "top.GLOBALS", // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose", // Facebook borked
    "fb_xd_fragment", // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See https://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived", // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ];
  if (IGNORE_ERRORS.includes(event.error?.message) || event.view.url.includes("devNotes")) {
    return false;
  }
  return true;
};
export const initializeDataDogLogging = (credentials: DataDogCredentials, user?: User) => {
  const isDataDogLoggingEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG_LOGGING);
  if (!isDataDogLoggingEnabled) {
    return;
  }

  datadogLogs.init({
    clientToken: credentials.tradingDashBoardClientToken,
    site: "datadoghq.com",
    env: isProdUrl() ? "prod" : "qa",
    service: "web-frontend",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "debug"],
    sessionSampleRate: 100,
    beforeSend,
  });

  if (user) {
    datadogLogs.setUser({
      id: user.mixpanelId,
      email: user.email,
      countryCode: user.countryCode,
      lang: user.lang,
    });
  }
};
export const initializeDataDogRUM = (credentials: DataDogCredentials, user: User) => {
  const isDataDogEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG);

  if (!isDataDogEnabled) return;

  const sampleRate = optimizelyClient.getFeatureVariableInteger(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG, "sampleRate");
  const premiumSampleRate = optimizelyClient.getFeatureVariableInteger(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "premiumSampleRate"
  );
  const trackInteractions = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackInteractions"
  );
  const trackFrustrations = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackFrustrations"
  );
  const trackSessionAcrossSubdomains = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackSessionAcrossSubdomains"
  );

  dataDogClient.onReady(() => {
    dataDogClient.init({
      applicationId: credentials.tradingDashBoardApplicationId,
      clientToken: credentials.tradingDashBoardClientToken,
      site: "datadoghq.com",
      service: "web-frontend",
      env: window.__DEV__ ? "dev" : "prod",
      sampleRate,
      premiumSampleRate,
      trackInteractions,
      trackFrustrations,
      trackSessionAcrossSubdomains,
    });

    if (user?.mixpanelId) {
      dataDogClient.setUser({
        id: user?.mixpanelId,
        email: user.email,
        name: user.fullName,
        countryCode: user.countryCode,
        lang: user.lang,
      });
    }
  });
};
